/*
 * IMPORT
 */
import React, { useState } from "react";
import _ from "underscore";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileUpload, MdErrorOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { IoClose, IoCheckmarkDoneSharp } from "react-icons/io5";
import { useMutation } from "@apollo/client"; // NPM: Apollo client for handling graphql request.
import { connect } from "react-redux"; // NPM: React-redux library.
import TimeInput from "./timeInput/timeInput";
import { useHistory } from "react-router-dom";

/*
 * CHAKRA UI IMPORT
 */
import {
  FormControl,
  FormLabel,
  Text,
  useDisclosure,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Input,
  Select,
  Checkbox,
  InputGroup,
  useToast,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import SellerFormCreate from "./__mutation__/index.sellerForm.mutation";

/*
 * OBJECT
 */
const TimeWrapper = ({ onDataFromChild }) => {
  const [timeDuration, setTimeDuration] = useState("");

  const onTimeChangeHandler = (val) => {
    const dur = parseInt(val.split(":")[0]) * 60 + parseInt(val.split(":")[1]);

    setTimeDuration(dur);
    onDataFromChild(dur);
  };

  return (
    <TimeInput
      name="duration"
      borderColor="none"
      className="s-input -time"
      onTimeChange={onTimeChangeHandler}
      placeholder="HH:MM" // Set the placeholder text
    />
  );
};

/*
 * OBJECT
 */
function SellerForm({ account }) {
  /*
   * STATE
   */
  const toast = useToast();

  const [formData, setFormData] = useState(initialState);
  const [castState, setCastState] = useState([]);
  const [horizontalUploadStatus, setHorizontalUploadStatus] = useState("");
  const [verticalUploadStatus, setVerticalUploadStatus] = useState("");
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const labelStyle = {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#B5C9DB",
  };
  const inputStyle = {
    width: "410px",
    height: "50px",
    border: "1px solid #3D445A",
    borderRadius: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-0.28px",
    color: "#878992",
  };
  const inputStyle1 = {
    width: "852px",
    height: "50px",
    border: "1px solid #3D445A",
    borderRadius: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-0.28px",
    color: "#878992",
  };
  const sellbutton = {
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontWeight: "500",
    lineHeight: "31.74px",
    letterSpacing: "-0.2px",
    backgroundColor: "#C30280",
    color: "#D9D9D9",
    cursor: "pointer",
  };

  const [SellerFormCreateMutation, { loading: loadingSellerFormCreate }] =
    useMutation(SellerFormCreate, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * FUNCTION
   */
  const handleAllRightsChange = () => {
    setFormData((prev) => {
      const allRightsValue = !prev.allRights;
      return {
        ...prev,
        allRights: allRightsValue,
        satelliteRight: allRightsValue,
        digitalRight: allRightsValue,
        airborneRight: allRightsValue,
      };
    });
  };

  const handleSellerForm = async () => {
    try {
      const response = await SellerFormCreateMutation({
        variables: {
          ageRating: formData?.ageRating,
          agreeTerms: formData?.agreeTerms,
          airborneRight: formData?.airborneRight,
          allRights: formData?.allRights,
          cast: castState,
          country: formData?.country,
          contentLink: formData?.contentLink,
          contentTitle: formData?.contentTitle,
          contentType: formData?.contentType,
          digitalRight: formData?.digitalRight,
          directorName: formData?.directorName,
          duration: formData?.duration,
          email: formData?.email,
          exclusive: formData?.exclusive,
          genre: formData?.genre,
          haveCopyrights: formData?.haveCopyrights,
          imdbRating: parseFloat(formData?.imdbRating),
          language: formData?.language,
          lastName: formData?.lastName,
          music: formData?.music,
          firstName: formData?.firstName,
          nonExclusive: !formData?.exclusive,
          phone: formData?.phone,
          plotSummary: formData?.plotSummary,
          producer: formData?.producer,
          satelliteRight: formData?.satelliteRight,
          storyWriter: formData?.storyWriter,
          territory: formData?.territory,
          territorySelect: formData?.territroySelect,
          thumbnailStoredAt: formData?.thumbnailStoredAt,
          verticalThumbnailStoredAt: formData?.verticalThumbnailStoredAt,
        },
      });

      const data = response.data.SellerFormCreate;

      if (!_.isEmpty(data)) {
        if (data.status === "SELLFORM_SUCCESSFULLY_ADDED") {
          resetForm(); // Reset form data after submission
          onClose(); // Close the modal after submission
          toast({
            title: "Form Submitted",
            description: "Your form has been submitted successfully!",
            status: "success",
            duration: 5000, // milliseconds
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error(error, "error");
      // Show error toast
      toast({
        title: "Error",
        description:
          "An error occurred while submitting the form Pls Try Again Later.",
        status: "error",
        duration: 5000, // milliseconds
        isClosable: true,
      });
    }
  };

  function handleCheckSellerForm(e){
    e.preventDefault();
    if(formData.haveCopyrights){
      handleSellerForm()
    } else {
      toast({
        title: "Agree to our terms and conditions",
        status: "warning",
        duration: 5000, // milliseconds
        isClosable: true,
      });
    }
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        thumbnailStoredAt: file,
      }));
      setHorizontalUploadStatus("success");
    } else {
      setHorizontalUploadStatus("error");
    }
  };
  const handleVerticalImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {

      setFormData((prevFormData) => ({
        ...prevFormData,
        verticalThumbnailStoredAt: file,
      }));
      setVerticalUploadStatus("success");
    } else {
      setVerticalUploadStatus("error");
    }
  };

  const handleCastState = () => {
    // Check if contentLink and summary are not empty

    if (formData.cast && formData.name) {
      // Create a new object with values from formData
      const newLink = {
        id: uuidv4(),
        cast: formData.cast,
        name: formData.name,
      };

      // Update castState with the new object
      setCastState((prevLinkState) => {
        return [...prevLinkState, newLink];
      });

      // Clear the cast and name fields in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        cast: "",
        name: "",
      }));
    }
  };

  function handleOpen() {
    if (account.isUserLoggedIn) {
      onOpen();
    } else {
      history.push("/signin");
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const form = { ...formData, [name]: value };

    setFormData(form);

    // Log the formData state to see if it's updating correctly
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      producer: "",
      directorName: "",
      cast: "",
      name: "",
      contentType: "",
      contentTitle: "",
      contentLink: "",
      plotSummary: "",
      duration: "",
      verticalThumbnailStoredAt: "",
      thumbnailStoredAt: "",
      genre: "",
      ageRating: "",
      imdbRating: "",
      music: "",
      storyWriter: "",
      country: "",
      language: "",
      exclusive: false,
      allRights: false,
      satelliteRight: false,
      digitalRight: false,
      airborneRight: false,
      territory: false,
      territroySelect: "",
      haveCopyrights: false,
      agreeTerms: false,
    });
    setVerticalUploadStatus("");
    setHorizontalUploadStatus("");
    setCastState([]);
  };

  const handleDuration = (data) => {
    // Handle the data received from the child component
    setFormData((prevLinkState) => ({
      ...prevLinkState,
      duration: data,
    }));
  };
  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleDeleteCastState = (id) => {
    // Filter out the item with the specified id
    const updatedCastState = castState.filter((item) => item.id !== id);

    // Update the state with the modified array
    setCastState(updatedCastState);
  };

  return (
    <Flex
      w="100%"
      justifyContent={{
        base: "center",
        md: "center",
        lg: "center",
        xl: "left",
      }}
    >
      <Button
        style={sellbutton}
        width={{ base: "92.3px", md: "170px" }}
        height={{ base: "31.5px", md: "58px" }}
        fontSize={{ base: "10.859px", md: "20px" }}
        _hover={{ bg: "#C30280" }}
        onClick={handleOpen}
      >
        Sell Content
      </Button>

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior="inside"
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          p="15px"
          borderRadius="20px"
          bg="rgba(42, 45, 62, 1)"
          position="fixed"
        >
          <ModalHeader
            fontFamily="Roboto"
            fontWeight="500"
            fontSize="32px"
            lineHeight="28.21px"
            letterSpacing="-0.64px"
            color="rgba(255, 255, 255, 1)"
          >
            Submit Your Content
          </ModalHeader>
          <ModalCloseButton
            size="lg"
            mt="15px"
            mr="10px"
            color="rgba(238, 93, 80, 1)"
          />
          <ModalBody pb={10} overflowY="auto">
            {/* /
            Form
            / */}

            <form onSubmit={(e) => handleCheckSellerForm(e)}>
              <Flex gap="1rem" direction="column">
                {/* /
                Upper Section of Form
                / */}

                <Flex gap="32px" justify="center" w="100%">
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Name</FormLabel>
                      <Input
                        name="firstName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.firstName}
                        placeholder="Name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Email ID</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        onChange={handleInputChange}
                        value={formData.email}
                        placeholder="Email id"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>
                        Producer’s Name/Production House Name
                      </FormLabel>
                      <Input
                        name="producer"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.producer}
                        placeholder="Name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Type of content</FormLabel>
                      <Select
                        name="contentType"
                        onChange={handleInputChange}
                        placeholder="Choose content type"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="featureFilm">Feature Film</option>
                        <option value="documentary">Documentary</option>
                        <option value="shortFilm">Short Film</option>
                        <option value="animatedFilm">Animated Film</option>
                        <option value="musical">Musical</option>
                        <option value="webSeries">Web Series</option>
                        <option value="vlog">Vlog</option>
                        <option value="kids">Kids</option>
                        <option value="sports">Sports</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Last name</FormLabel>
                      <Input
                        name="lastName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.lastName}
                        placeholder="Last name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Contact number</FormLabel>
                      <Input
                        name="phone"
                        type="number"
                        onChange={handleInputChange}
                        value={formData.phone}
                        placeholder="Mobile number"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Director’s Name</FormLabel>
                      <Input
                        name="directorName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.directorName}
                        placeholder="Director name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Content title</FormLabel>
                      <Input
                        name="contentTitle"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.contentTitle}
                        placeholder="Title"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Cast
                and
                Name
                / */}

                <Flex
                  p="1rem 0px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w="100%"
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  >
                    {castState.map((item, index) => (
                      <Flex
                        p="20px"
                        borderRadius="5px"
                        bg="#D9D9D9"
                        key={item.id}
                        align="top"
                        gap="1rem"
                        style={labelStyle}
                        w="100%"
                      >
                        <Flex
                          w="100%"
                          gap=".5rem"
                          color="#666874"
                          direction="column"
                        >
                          <Text>
                            Star Cast:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.cast}
                            </span>
                          </Text>
                          <Text>
                            Name:{" "}
                            <span style={{ color: "#1E202D" }}>
                              {item.name}
                            </span>
                          </Text>
                        </Flex>
                        <Flex>
                          <IoClose
                            size="20px"
                            cursor="pointer"
                            color="#7551FF"
                            onClick={() => handleDeleteCastState(item.id)}
                          />
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>

                  <Flex direction="column" gap="1rem">
                    <Flex gap="32px">
                      <FormControl>
                        <FormLabel style={labelStyle}>Star Cast</FormLabel>
                        <Select
                          name="cast"
                          value={formData.cast}
                          onChange={handleInputChange}
                          placeholder="Choose cast"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                          color="#666874"
                        >
                          {/* Add your options here */}

                          <option value="ACTOR">Actor</option>
                          <option value="Story Writer">Story writer</option>
                          <option value="Producer">Producer</option>
                          <option value="Lyricist">Lyricist</option>
                          <option value="Music">Music</option>
                          {/* Add more options as needed */}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel style={labelStyle}>Name</FormLabel>
                        <Input
                          name="name"
                          type="text"
                          onChange={handleInputChange}
                          value={formData.name}
                          placeholder="Name"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                        />
                      </FormControl>
                    </Flex>

                    <Flex
                      w="54px"
                      h="50px"
                      border="1px solid #3D445A"
                      borderRadius="12px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                      onClick={() => handleCastState()}
                    >
                      <FaPlus color="#666874" />
                    </Flex>
                  </Flex>
                </Flex>

                {/* /
                Link of Content
                and
                Plot Summary
                and 
                Duration
                and
                Thumbnail
                / */}

                <Flex
                  p="1rem 0px"
                  align="center"
                  direction="column"
                  bg="#1E202D"
                  w="100%"
                >
                  <Flex
                    align="center"
                    flexWrap="wrap"
                    p="10px 10px 10px 30px"
                    w="100%"
                    gap="1rem"
                  ></Flex>
                  <Flex gap="1rem" direction="column">
                    <Flex gap="32px" align="center">
                      <FormControl isRequired>
                        <FormLabel style={labelStyle}>
                          Link of content
                        </FormLabel>
                        <Input
                          name="contentLink"
                          type="text"
                          onChange={handleInputChange}
                          value={formData.contentLink}
                          placeholder="Paste link"
                          _placeholder={{ color: "#878992" }}
                          style={inputStyle}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel style={labelStyle}>Duration</FormLabel>
                        <TimeWrapper onDataFromChild={handleDuration} />
                      </FormControl>
                    </Flex>

                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Plot summary</FormLabel>
                      <Input
                        name="plotSummary"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.plotSummary}
                        placeholder="Write summary"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle1}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <Flex align="center">
                        <FormLabel style={labelStyle}>
                          Upload content horizontal poster (only the film name should be metioned on the poster)
                        </FormLabel>
                        {horizontalUploadStatus === "success" ? (
                          <IoCheckmarkDoneSharp color="white" />
                        ) : horizontalUploadStatus === "error" ? (
                          <MdErrorOutline color="white" />
                        ) : (
                          ""
                        )}
                      </Flex>

                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document.getElementById("thumbnailInput").click();
                          }}
                          style={inputStyle1}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="thumbnailInput"
                          name="thumbnailStoredAt"
                          onChange={handleImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl isRequired>
                      <Flex align="center">
                        <FormLabel style={labelStyle}>
                          Upload content vertical poster (only the film name should be metioned on the poster)
                        </FormLabel>
                        {verticalUploadStatus === "success" ? (
                          <IoCheckmarkDoneSharp color="white" />
                        ) : verticalUploadStatus === "error" ? (
                          <MdErrorOutline color="white" />
                        ) : (
                          ""
                        )}
                      </Flex>
                      <InputGroup align="center">
                        <Button
                          border="1px solid #3D445A"
                          bg="transparent"
                          _hover={{ bg: "transparent" }}
                          onClick={() => {
                            // Trigger the hidden file input when the button is clicked
                            document
                              .getElementById("verticalThumbnailInput")
                              .click();
                          }}
                          style={inputStyle1}
                          position="relative"
                        >
                          <MdOutlineFileUpload
                            size="25px"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Button>
                        {/* Hidden file input */}
                        <Input
                          type="file"
                          id="verticalThumbnailInput"
                          name="verticalThumbnailStoredAt"
                          onChange={handleVerticalImageUpload}
                          display="none"
                        />
                      </InputGroup>
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Mid Section of Form
                / */}

                <Flex gap="32px" justify="center" w="100%">
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Genre</FormLabel>
                      <Select
                        name="genre"
                        onChange={handleInputChange}
                        placeholder="Genre"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="action">Action</option>
                        <option value="comedy">Comedy</option>
                        <option value="romance">Romance</option>
                        <option value="drama">Drama</option>
                        <option value="horror">Horror</option>
                        <option value="scienceFiction">Science Fiction</option>
                        <option value="fantasy">Fantasy</option>
                        <option value="mystery">Mystery</option>
                        <option value="thriller">Thriller</option>
                        <option value="music">Music</option>
                        <option value="education">Education</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel style={labelStyle}>IMDB Rating</FormLabel>
                      <Input
                        name="imdbRating"
                        type="number"
                        onChange={handleInputChange}
                        value={formData.imdbRating}
                        placeholder="IMDB Rating"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        min={1}
                        max={10}
                        step={0.1}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel style={labelStyle}>Music</FormLabel>
                      <Input
                        name="music"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.music}
                        placeholder="Music"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Content language</FormLabel>
                      <Select
                        name="language"
                        onChange={handleInputChange}
                        placeholder="Choose content language"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="english">English</option>
                        <option value="hindi">Hindi</option>
                        <option value="telugu">Telugu</option>
                        <option value="assamese">Assamese</option>
                        <option value="konkani">Konkani</option>
                        <option value="gujarati">Gujarati</option>
                        <option value="kannada">Kannada</option>
                        <option value="malayalam">Malayalam</option>
                        <option value="marathi">Marathi</option>
                        <option value="manipuri">Manipuri</option>
                        <option value="mizo">Mizo</option>
                        <option value="odia">Odia</option>
                        <option value="punjabi">Punjabi</option>
                        <option value="tamil">Tamil</option>
                        <option value="bengali">Bengali</option>
                        <option value="ladakhi">Ladakhi</option>
                        <option value="urdu">Urdu</option>
                        <option value="kashmiri">Kashmiri</option>
                        <option value="chhattisgarhi">Chhattisgarhi</option>
                        <option value="dogri">Dogri</option>
                        <option value="awadhi">Awadhi</option>
                        <option value="bundelkhandi">Bundelkhandi</option>
                        <option value="maithili">Maithili</option>
                        <option value="bhojpuri">Bhojpuri</option>
                        <option value="garhwali">Garhwali</option>
                        <option value="kumaoni">Kumaoni</option>
                        <option value="pahari ">Himachali (Pahari)</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Age rating</FormLabel>
                      <Select
                        name="ageRating"
                        onChange={handleInputChange}
                        placeholder="Choose age rating"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                        color="#666874"
                      >
                        {/* Add your options here */}
                        <option value="7">7+</option>
                        <option value="13">13+</option>
                        <option value="16">16+</option>
                        <option value="18">18+</option>
                        {/* Add more options as needed */}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel style={labelStyle}>Story writer</FormLabel>
                      <Input
                        name="storyWriter"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.storyWriter}
                        placeholder="Story writer"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel style={labelStyle}>Country</FormLabel>
                      <Input
                        name="country"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.country}
                        placeholder="Country"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                {/* /
                Lower section of Form
                / */}

                <Flex mt="1rem" ml="3rem" gap="30px">
                  <RadioGroup
                    style={labelStyle}
                    onChange={() =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          exclusive: !prev.exclusive,
                        };
                      })
                    }
                    value={formData.exclusive}
                  >
                    <Stack direction="row">
                      <Radio isChecked={formData.exclusive ===  true} value={true}>Exclusive</Radio>
                      <Radio isChecked={formData.exclusive ===  false} value={false}>Non Exclusive</Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>

                <Flex pl="3rem" mt="2rem" direction="column" gap="1rem">
                  <Text
                    mb="10px"
                    fontFamily="Roboto"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="16px"
                    letterSpacing="0.02em"
                    color="rgba(255, 255, 255, 1)"
                  >
                    Film Rights Specification Agreement to Terms and Conditions
                  </Text>
                  <Checkbox
                    style={labelStyle}
                    name="allRights"
                    checked={formData.allRights}
                    isChecked={formData.allRights}
                    onChange={handleAllRightsChange}
                  >
                    All Rights
                  </Checkbox>
                  <Checkbox
                    style={labelStyle}
                    name="satelliteRight"
                    checked={formData.satelliteRight}
                    isChecked={formData.satelliteRight}
                    onChange={() =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          satelliteRight: !prev.satelliteRight,
                        };
                      })
                    }
                  >
                    Satellite Rights
                  </Checkbox>
                  <Checkbox
                    style={labelStyle}
                    name="digitalRight"
                    checked={formData.digitalRight}
                    isChecked={formData.digitalRight}
                    onChange={() =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          digitalRight: !prev.digitalRight,
                        };
                      })
                    }
                  >
                    Digital Rights
                  </Checkbox>
                  <Checkbox
                    style={labelStyle}
                    name="airborneRight"
                    checked={formData.airborneRight}
                    isChecked={formData.airborneRight}
                    onChange={() =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          airborneRight: !prev.airborneRight,
                        };
                      })
                    }
                  >
                    Airborne Rights
                  </Checkbox>
                  <Flex align="bottom" gap="10px">
                    <Checkbox
                      style={labelStyle}
                      name="territory"
                      checked={formData.territory}
                      isChecked={formData.territory}
                      onChange={() =>
                        setFormData((prev) => {
                          return {
                            ...prev,
                            territory: !prev.territory,
                          };
                        })
                      }
                    >
                      Territory. Please Specify
                    </Checkbox>
                    <Select
                      w="150px" // Set the width to 80px
                      h="30px" // Set the height to 20px
                      name="territroySelect"
                      onChange={handleInputChange}
                      placeholder="Choose territory"
                      _placeholder={{ color: "#878992" }}
                      border="1px solid #878992"
                      borderRadius="16px"
                      fontFamily="Roboto"
                      fontWeight="400"
                      fontSize="14px"
                      lineHeight="14px"
                      letterSpacing="-0.28px"
                      color="#878992"
                    >
                      {/* Add your options here */}
                      <option value="nepal">Nepal</option>
                      <option value="sriLanka">Sri Lanka</option>
                      <option value="bangladesh">Bangladesh</option>

                      {/* Add more options as needed */}
                    </Select>
                  </Flex>
                </Flex>

                <Flex pl="3rem" mt="2rem" direction="column" gap="1rem">
                  <Text
                    mb="20px"
                    fontFamily="Roboto"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="16px"
                    letterSpacing="0.02em"
                    color="rgba(255, 255, 255, 1)"
                  >
                    Agreement to Terms and Conditions
                  </Text>
                  <Checkbox
                    style={labelStyle}
                    name="haveCopyrights"
                    checked={formData.haveCopyrights}
                    isChecked={formData.haveCopyrights}
                    onChange={() =>
                      setFormData((prev) => {
                        return {
                          ...prev,
                          haveCopyrights: !prev.haveCopyrights,
                        };
                      })
                    }
                  >
                    I confirm that I have the copyrights/intellectual property
                    rights for submitted content.
                  </Checkbox>
                </Flex>
                <Flex
                  color="rgba(43, 54, 116, 1)"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="16px"
                  fontFamily="Roboto"
                  letterSpacing="-0.32px"
                  justifyContent="center"
                  mt="3rem"
                >
                  <Button
                    _focus={{ outline: "2px solid rgba(66, 153, 225, 0.6)" }}
                    type="submit"
                    width="385px"
                    height="54px"
                    borderRadius="10px"
                    bg="rgba(255, 255, 255, 1)"
                  >
                    <Text
                      bg="linear-gradient(96.31deg, #052DB0 7.3%, #C30280 118.79%)"
                      bgClip="text"
                    >
                      {loadingSellerFormCreate ? (
                        <Spinner color="black" />
                      ) : (
                        <Text>Submit</Text>
                      )}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  producer: "",
  directorName: "",
  contentType: "",
  contentTitle: "",
  cast: "",
  name: "",
  contentLink: "",
  duration: "",
  plotSummary: "",
  thumbnailStoredAt: "",
  verticalThumbnailStoredAt: "",
  genre: "",
  language: "",
  imdbRating: "",
  ageRating: "",
  music: "",
  storyWriter: "",
  country: "",
  exclusive: false,
  allRights: false,
  satelliteRight: false,
  digitalRight: false,
  airborneRight: false,
  territory: false,
  territroySelect: "",
  haveCopyrights: false,
  agreeTerms: false,
};
/*
 * EXPORT
 */

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(SellerForm);
