/* eslint-disable no-undefined */
/* eslint-disable consistent-return */
/* eslint-disable init-declarations */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { isValid } from "./validate";
const inputStyle1 = {
  width: "410px",
  height: "50px",
  border: "1px solid #3D445A",
  borderRadius: "16px",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "14px",
  letterSpacing: "-0.28px",
  color: "#878992",
  padding: "2px",
  backgroundColor: "#1e202d",
};

const TimeInput = ({
  initTime,
  disabled,
  mountFocus,
  onTimeChange,
  type,
  placeholder,
  className,
  name,
  onBlurHandler,
}) => {
  const [time, setTime] = useState(initTime || "");

  const _input = useRef(null);

  useEffect(() => {
    if (!disabled && mountFocus) {
      setTimeout(() => {
        _input.current.focus();
      }, 0);
    }
  }, [disabled, mountFocus]);

  let lastVal = "";

  const onChangeHandler = (val) => {
    if (val === time) {
      return;
    }
    if (isValid(val)) {
      if (val.length === 2 && lastVal.length !== 3 && val.indexOf(":") === -1) {
        val += ":";
      }

      if (val.length === 2 && lastVal.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return false;
      }

      lastVal = val;

      setTime(val);

      if (val.length === 5) {
        onTimeChange(val);
      }
    }
  };

  const getType = () => {
    if (type) {
      return type;
    }

    return "tel";
  };

  return (
    <input
      name={name ? name : undefined}
      className={className}
      type={getType()}
      disabled={disabled}
      placeholder={placeholder}
      value={time}
      onChange={(e) => onChangeHandler(e.target.value)}
      // OnFocus={onFocusHandler ? (e) => onFocusHandler(e) : undefined}
      onBlur={onBlurHandler ? (e) => onBlurHandler(e) : undefined}
      ref={_input}
      style={{
        ...inputStyle1,
        "::placeholder": {
          margin: "0 5px", // Add margin to the placeholder text
        },
      }}
    />
  );
};

TimeInput.defaultProps = {
  placeholder: " ",
};

export default TimeInput;
