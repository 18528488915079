/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import _ from "underscore";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
/*
 * CHAKRA IMPORTS
 */
import { Image, Flex, Text, useToast, Spinner } from "@chakra-ui/react";

import ItemDeleteFromCart from "./__mutation__/index.removeFromCart.mutation";

/*
 * OBJECT
 */
function CartVideos({ data, account }) {
  /*
   * States
   */
  const toast = useToast();
  const [itemIdToRemove, setItemIdToRemove] = useState(null);
  const [ItemDeleteFromCartMutation, { loading: ItemDeleteFromCartLoading }] =
    useMutation(ItemDeleteFromCart, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const videoSmallTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "22px",
  };

  const videoBigTextStyle = {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0.2px",
  };

  /*
   * Functions
   */

  const handleRemoveFromCart = async (id) => {
    try {
      const response = await ItemDeleteFromCartMutation({
        variables: {
          cartItemId: id,
        },
      });

      const content = response.data.ItemDeleteFromCart;

      if (!_.isEmpty(content)) {
        if (content?.status === "CART_ITEM_REMOVED_SUCCESSFULLY") {
          toast({
            title: "Item Removed",
            description: "Item Removed successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          data?.refetch();
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  function formatDuration(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }

  useEffect(() => {
    data?.refetch();
  }, [data]);

  return (
    <>
      {data?.data?.CartItemRead?.data?.[0]?.map((item, index) => (
        <Flex
          p="15px"
          key={item?.id}
          borderRadius="10px"
          bg="#000000"
          w="100%"
          h="190px"
          gap="1rem"
        >
          <Image w="300px" h="150px" src={item?.SellForm?.thumbnailStoredAt} />
          <Flex w="100%" justify="space-between">
            <Flex gap=".5rem" direction="column">
              <Text style={videoBigTextStyle}>
                {item?.SellForm?.contentTitle}
              </Text>
              <Text style={videoSmallTextStyle}>
                {item?.SellForm?.plotSummary}
              </Text>
              <Flex mt="1rem" gap="10px" direction="column">
                <Flex
                  fontFamily="Roboto"
                  fontWeight="700"
                  fontSize="18.32px"
                  lineHeight="21.54px"
                  letterSpacing="-0.36px"
                  gap="3rem"
                >
                  <Text color="rgba(69, 211, 105, 1)">
                    {new Date(item?.SellForm?.createdAt).getFullYear()}
                  </Text>
                  <Text fontWeight="400" color="rgba(186, 186, 186, 1)">
                    {formatDuration(item?.SellForm?.duration)}
                  </Text>
                  <Flex
                    borderRadius="3px"
                    align="center"
                    justify="center"
                    h="22px"
                    w="43px"
                    border="1px solid rgba(116, 116, 116, 1)"
                  >
                    <Text
                      fontFamily="Roboto"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="18.54px"
                      color="rgba(255, 255, 255, 1)"
                    >
                      HD
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center" gap="3rem">
                  <Flex color="white" align="center" gap="5px">
                    <Flex
                      w="70px"
                      h="20px"
                      justify="center"
                      align="center"
                      border="1px solid rgba(116, 116, 116, 1)"
                    >
                      {`${item?.SellForm?.ageRating === "18" ? "A" : "U/A"} ${
                        item?.SellForm?.ageRating
                      }+`}
                    </Flex>
                    {item?.SellForm?.ageRating === "18" ? (
                      <Text>
                        <span style={{ textTransform: "capitalize" }}>
                          gore, language, mature themes, violence
                        </span>
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Text
                    fontFamily="Roboto"
                    fontSize="15px"
                    fontWeight="400"
                    lineHeight="18.54px"
                    color="rgba(255, 255, 255, 1)"
                    textTransform="capitalize"
                  >
                    {item?.SellForm?.genre}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              {ItemDeleteFromCartLoading && itemIdToRemove === item?.id ? (
                <Spinner color="white" />
              ) : (
                <IoClose
                  cursor="pointer"
                  color="white"
                  size="25px"
                  onClick={() => {
                    handleRemoveFromCart(item?.id);
                    setItemIdToRemove(item?.id);
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
    </>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(CartVideos);
