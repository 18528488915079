/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
/*
 * CHAKRA UI IMPORT
 */
import {
  FormControl,
  FormLabel,
  Text,
  useDisclosure,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Input,
  useToast,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import BuyFormCreate from "./__mutation__/index.buyerForm.mutation";
import { useMutation } from "@apollo/client";

/*
 * OBJECT
 */
function BuyerForm({ data , account }) {
  /*
   * STATE
   */
  const toast = useToast();
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    companyUrl: "",
    contentType: ""
  });

  const [ids, setIds] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [BuyFormCreateMutation, { loading: loadingBuyFormCreate }] =
    useMutation(BuyFormCreate, {
      context: {
        headers: {
          "l-authorization": account.token},
      },
    });

  const labelStyle = {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#B5C9DB",
  };
  const inputStyle = {
    width: "410px",
    height: "50px",
    border: "1px solid #3D445A",
    borderRadius: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    letterSpacing: "-0.28px",
    color: "#878992",
  };

  /*
   * FUNCTION
   */

  const handleBuyerForm = async (e) => {
    e.preventDefault();
    try {
      const response = await BuyFormCreateMutation({
        variables: {
          cartId: ids,
          companyName: formData?.companyName,
          companyUrl: formData?.companyUrl,
          preferredContent: formData?.contentType,
          email: formData?.email,
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          phone: formData?.phone,
        },
      });
      const data = response.data.BuyFormCreate;

      if (!_.isEmpty(data) && data.status === "BUYFORM_CREATED") {
        toast({
          title: "Item Removed",
          description: "Item Removed successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        resetForm();
        onClose();
        history.push("/");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Pls Try Again Later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  function handleOpen() {
    if (data) {
      const newIds = data?.data?.CartItemRead?.data?.reduce((acc, currentItem) => {
        const itemIds = currentItem?.map((item) => item.id);
        return [...acc, ...itemIds];
      }, []);
      setIds(newIds);
      onOpen();
    }
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const form = { ...formData, [name]: value };
    setFormData(form);
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      companyUrl: "",
      contentType: "",
    });
    setIds([]);
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);


  return (
    <Flex p="20px 25px 20px 20px" w="100%" justify="flex-end">
      <Button
        zIndex="1"
        w="465px"
        h="61px"
        borderRadius="8px"
        bg="rgba(5, 45, 176, 1)"
        color="rgba(255, 255, 255, 1)"
        _hover={{ bg: "rgba(5, 45, 176, 1)", color: "rgba(255, 255, 255, 1)" }}
        fontFamily="Poppins"
        fontWeight="600"
        fontSize="20px"
        lineHeight="31.74px"
        letterSpacing="-0.4px"
        gap=".5rem"
        onClick={handleOpen}
        cursor="pointer"
      >
        Proceed to process
      </Button>
      

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        {" "}
        <ModalOverlay />
        <ModalContent
          p="15px"
          borderRadius="20px"
          bg="rgba(42, 45, 62, 1)"
          position="fixed"
        >
          <ModalHeader
            fontFamily="Roboto"
            fontWeight="500"
            fontSize="32px"
            lineHeight="28.21px"
            letterSpacing="-0.64px"
            color="rgba(255, 255, 255, 1)"
          >
            Fill Your Information
          </ModalHeader>
          <ModalCloseButton
            size="lg"
            mt="15px"
            mr="10px"
            color="rgba(238, 93, 80, 1)"
          />
          <ModalBody pb={10} overflowY="auto">
            {/* /
            Form
            / */}
            <form onSubmit={(e) => handleBuyerForm(e)}>
              <Flex mt="2rem" gap="1rem" direction="column">
                {/* /
                Upper Section of Form
                / */}
                <Flex gap="32px" justify="center" w="100%">
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>First Name</FormLabel>
                      <Input
                        name="firstName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.firstName}
                        placeholder="First Name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Email ID</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        onChange={handleInputChange}
                        value={formData.email}
                        placeholder="Email ID"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Company Name</FormLabel>
                      <Input
                        name="companyName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.companyName}
                        placeholder="Company Name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                  <Flex gap="1rem" direction="column">
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Last Name</FormLabel>
                      <Input
                        name="lastName"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.lastName}
                        placeholder="Last Name"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Contact Number</FormLabel>
                      <Input
                        name="phone"
                        type="number"
                        onChange={handleInputChange}
                        value={formData.phone}
                        placeholder="Mobile Number"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Company URL</FormLabel>
                      <Input
                        name="companyUrl"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.companyUrl}
                        placeholder="Url"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel style={labelStyle}>Preferred Type of content</FormLabel>
                      <Input
                        name="contentType"
                        type="text"
                        onChange={handleInputChange}
                        value={formData.contentType}
                        placeholder="Title"
                        _placeholder={{ color: "#878992" }}
                        style={inputStyle}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                <Flex
                  color="rgba(43, 54, 116, 1)"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="16px"
                  fontFamily="Roboto"
                  letterSpacing="-0.32px"
                  justifyContent="center"
                  mt="3rem"
                >
                  <Button
                    _focus={{ outline: "2px solid rgba(66, 153, 225, 0.6)" }}
                    type="submit"
                    width="273px"
                    height="54px"
                    borderRadius="10px"
                    bg="rgba(255, 255, 255, 1)"
                  >
                    <Text
                      bg="linear-gradient(96.31deg, #052DB0 7.3%, #C30280 118.79%)"
                      bgClip="text"
                    >
                      {loadingBuyFormCreate ? (
                        <Spinner color="black" />
                      ) : (
                        "Submit"
                      )}
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

/*
 * EXPORT
 */
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(BuyerForm);
