/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery(
    $ageRating: [String]
    $airborneRight: Boolean
    $ContentType: String
    $contentTitle: String
    $digitalRight: Boolean
    $endDate: Date
    $endImdbRrating: Float
    $genre: [String]
    $language: [String]
    $exclusive: Boolean
    $nonExclusive: Boolean
    $satelliteRight: Boolean
    $startDate: Date
    $startImdbRating: Float
  ) {
    SellerFormRead(
      ageRating: $ageRating
      airborneRight: $airborneRight
      ContentType: $ContentType
      contentTitle: $contentTitle
      digitalRight: $digitalRight
      endDate: $endDate
      endImdbRrating: $endImdbRrating
      genre: $genre
      language: $language
      exclusive: $exclusive
      nonExclusive: $nonExclusive
      satelliteRight: $satelliteRight
      startDate: $startDate
      startImdbRating: $startImdbRating
    ) {
      data
      status
      message
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
