/*
 * IMPORTS
 */
import { useEffect, useState, useCallback } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import _ from "underscore";
import Carousel from "./Components/carousel";
import { useLazyQuery } from "@apollo/client";
import { connect } from "react-redux"; // NPM: React-redux library.
import Debounce from "lodash/debounce"; // Npm: lodash library.
import Loader from "../../../../components/Loader/loader"
/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Text,
  Input,
  RadioGroup,
  Stack,
  Radio,
  CheckboxGroup,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Popover,
  PopoverTrigger,
  Button,
  Spinner,
  useToast,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import SellerFormRead from "./__query__/index.getSellerMovies.query";

const language = [
  { name: "English", value: "english" },
  { name: "Hindi", value: "hindi" },
  { name: "Telugu", value: "telugu" },
  { name: "Assamese", value: "assamese" },
  { name: "Konkani", value: "konkani" },
  { name: "Gujarati", value: "gujarati" },
  { name: "Kannada", value: "kannada" },
  { name: "Malayalam", value: "malayalam" },
  { name: "Marathi", value: "marathi" },
  { name: "Manipuri", value: "manipuri" },
  { name: "Mizo", value: "mizo" },
  { name: "Odia", value: "odia" },
  { name: "Punjabi", value: "punjabi" },
  { name: "Tamil", value: "tamil" },
  { name: "Bengali", value: "bengali" },
  { name: "Urdu", value: "urdu" },
  { name: "Kashmiri", value: "kashmiri" },
  { name: "Chhattisgarhi", value: "chhattisgarhi" },
  { name: "Dogri", value: "dogri" },
  { name: "Awadhi", value: "awadhi" },
  { name: "Bundelkhandi", value: "bundelkhandi" },
  { name: "Maithili", value: "maithili" },
  { name: "Bhojpuri", value: "bhojpuri" },
  { name: "Garhwali", value: "garhwali" },
  { name: "Kumaoni", value: "kumaoni" },
  { name: "Pahari", value: "pahari" },
];
const genre = [
  { name: "Action", value: "action" },
  { name: "Romance", value: "romance" },
  { name: "Comedy", value: "comedy" },
  { name: "Drama", value: "drama" },
  { name: "Documentary", value: "documentary" },
  { name: "Horror", value: "horror" },
  { name: "Fantasy", value: "fantasy" },
  { name: "Science Fiction", value: "scienceFiction" },
  { name: "Mystery", value: "mystery" },
  { name: "Thriller", value: "thriller" },
  { name: "Music", value: "music" },
  { name: "Education", value: "education" },
];
const ageRating = [
  { name: "7+", value: "7" },
  { name: "13+", value: "13" },
  { name: "16+", value: "16" },
  { name: "18+", value: "18" },
];

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */
  const toast = useToast();
  const [value, setValue] = useState(null);
  const [sellerRead, setSellerRead] = useState();
  const [imbdRatingValues, setImbdRatingValues] = useState([1, 10]);
  const [rights, setRights] = useState({
    satellite: null,
    digital: null,
    airborne: null,
  });
  const [license, setLicense] = useState({
    exclusive: null,
    nonExclusive: null,
  });
  const [selectedGenres, setSelectedGenres] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState(null);
  const [selectedAgeRating, setSelectedAgeRating] = useState(null);

  const [SellerFormReadQuery, { loading: SellerFormReadLoading }] =
    useLazyQuery(SellerFormRead, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  /*
   * FUNCTION
   */
  const handleGenreChange = (value) => {
    setSelectedGenres(
      (selectedGenres ?? []).includes(value)
        ? selectedGenres.filter((genre) => genre !== value)
        : [...(selectedGenres ?? []), value]
    );
  };
  const handleLanguageChange = (value) => {
    setSelectedLanguages(
      (selectedLanguages ?? []).includes(value)
        ? selectedLanguages.filter((genre) => genre !== value)
        : [...(selectedLanguages ?? []), value]
    );
  };
  const handleAgeRatingChange = (value) => {
    setSelectedAgeRating(
      (selectedAgeRating ?? []).includes(value)
        ? selectedAgeRating.filter((genre) => genre !== value)
        : [...(selectedAgeRating ?? []), value]
    );
  };
  const handleLicenseChange = (value) => {
    setLicense({ ...license, [value]: !license[value] });
  };
  const handleImdbRatingChange = (newValues) => {
    setImbdRatingValues(newValues);
  };
  const resetFilters = () => {
    setValue(null);
    setImbdRatingValues([1, 10]);
    setRights({
      satellite: null,
      digital: null,
      airborne: null,
    });
    setLicense({
      exclusive: null,
      nonExclusive: null,
    });
    setSelectedGenres([]);
    setSelectedLanguages([]);
    setSelectedAgeRating([]);
  };
  const handleFilter = async (contentTitle) => {
    try {
      const response = await SellerFormReadQuery({
        variables: {
          ContentType: value,
          contentTitle: contentTitle,
          ageRating: selectedAgeRating,
          airborneRight: rights?.airborne,
          digitalRight: rights?.digital,
          satelliteRight: rights?.satellite,
          exclusive: license?.exclusive === false ? null : license?.exclusive,
          nonExclusive:
            license?.nonExclusive === false ? null : license?.nonExclusive,
          // endDate: $endDate
          startImdbRating: imbdRatingValues[0],
          endImdbRrating: imbdRatingValues[1],
          genre: selectedGenres,
          language: selectedLanguages,
          // startDate: $startDate
        },
      });

      const data = response.data.SellerFormRead;

      if (!_.isEmpty(data)) {
        setSellerRead(data);
      }
    } catch (error) {}
  };
  const handleClearAll = () => {
    resetFilters();
    handleFilter();
  };
  const handleContentTypeCheck = () => {
    if (value === null) {
      toast({
        title: "Please Select Content Type",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      resetFilters();
    } else {
      handleFilter();
    }
  };
  const handleSearchInput = useCallback(
    Debounce((e) => handleFilter(e.target.value), 1000),
    []
  );

  /*
   * USEEFFECT
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleFilter();
  }, [value]);

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        pt="5rem"
        fontFamily="Poppins"
        fontSize={["clamp(12px, 1.5vw, 15px)"]}
        fontWeight="500"
        color="#263238"
      >
        <Flex alignItems="center" justify="center">
          <Input
            color="white"
            placeholder="Search Movie Title"
            width="420px"
            onChange={handleSearchInput}
          />
        </Flex>

        <Flex>
          <Flex
            h="80vh"
            width="15%"
            borderRadius="10px"
            bg="#2a2d3e"
            direction="column"
          >
            <Flex justify="space-between" p="1rem" align="center">
              <Text fontSize="15px" fontWeight="700" color="#fff">
                FILTERS
              </Text>
              <Text
                cursor="pointer"
                fontSize="10px"
                fontWeight="700"
                color="#7551FF"
                onClick={handleClearAll}
              >
                CLEAR ALL
              </Text>
            </Flex>
            <Flex overflowY="scroll" direction="column">
              <hr style={{ border: "1px solid #424551" }} />
              {/* CONTENT TYPE */}
              <Flex p="1rem" direction="column">
                <Text color="#fff" fontSize="15px" fontWeight="700">
                  Content Type
                </Text>
                <RadioGroup
                  mt="1rem"
                  color="#fff"
                  onChange={setValue}
                  value={value}
                >
                  <Stack direction="column">
                    <Radio
                      isChecked={value === "featureFilm"}
                      value="featureFilm"
                    >
                      Feature Film
                    </Radio>
                    <Radio
                      isChecked={value === "documentary"}
                      value="documentary"
                    >
                      Documentary
                    </Radio>
                    <Radio isChecked={value === "shortFilm"} value="shortFilm">
                      Short Film
                    </Radio>
                    <Radio
                      isChecked={value === "animatedFilm"}
                      value="animatedFilm"
                    >
                      Animated Film
                    </Radio>
                    <Radio isChecked={value === "animatedFilm"} value="c">
                      Musical
                    </Radio>
                    <Radio isChecked={value === "webSeries"} value="webSeries">
                      Web Series
                    </Radio>
                    <Radio isChecked={value === "vlog"} value="vlog">
                      Vlog
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
              <hr style={{ border: "1px solid #424551" }} />
              {/* DATES */}
              {/* <Flex direction="column" p="1rem">
              <Text color="#fff" fontSize="15px" fontWeight="700">
                Date
              </Text>
              <CheckboxGroup colorScheme="blue" color="#fff">
                <Stack mt="1rem" direction="column" color="#fff">
                  <Checkbox value="0">Today</Checkbox>
                  <Checkbox value="7">Last 7 Days</Checkbox>
                  <Checkbox value="30">This Month</Checkbox>
                  <Checkbox value="60">Last 2 Month</Checkbox>
                </Stack>
              </CheckboxGroup>
            </Flex> */}

              {/* LICENSES */}
              <hr style={{ border: "1px solid #424551" }} />
              <Flex direction="column" p="1rem">
                <Text color="#fff" fontSize="15px" fontWeight="700">
                  Licenses
                </Text>
                <CheckboxGroup colorScheme="blue">
                  <Stack mt="1rem" direction="column" color="#fff">
                    <Checkbox
                      isChecked={license.exclusive}
                      onChange={() => handleLicenseChange("exclusive")}
                    >
                      Exclusive
                    </Checkbox>
                    <Checkbox
                      isChecked={license.nonExclusive}
                      onChange={() => handleLicenseChange("nonExclusive")}
                    >
                      Non Exclusive
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Flex>

              {/* RIGHTS */}
              <hr style={{ border: "1px solid #424551" }} />
              <Flex direction="column" p="1rem">
                <Text color="#fff" fontSize="15px" fontWeight="700">
                  Rights
                </Text>
                <Stack mt="1rem" direction="column" color="#fff">
                  <Flex direction="column">
                    <Text>Satellite Rights</Text>

                    <RadioGroup
                      mt="1rem"
                      color="#fff"
                      onChange={(value) =>
                        setRights({
                          ...rights,
                          satellite: value === "true",
                        })
                      }
                      value={rights?.satellite?.toString()}
                    >
                      <Flex gap="20px">
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Flex>
                    </RadioGroup>
                  </Flex>
                  <Flex direction="column">
                    <Text>Digital Rights</Text>

                    <RadioGroup
                      mt="1rem"
                      color="#fff"
                      onChange={(value) =>
                        setRights({
                          ...rights,
                          airborne: value === "true",
                        })
                      }
                      value={rights?.airborne?.toString()}
                    >
                      <Flex gap="20px">
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Flex>
                    </RadioGroup>
                  </Flex>
                  <Flex direction="column">
                    <Text>Airborne Rights</Text>

                    <RadioGroup
                      mt="1rem"
                      color="#fff"
                      onChange={(value) =>
                        setRights({
                          ...rights,
                          digital: value === "true",
                        })
                      }
                      value={rights?.digital?.toString()}
                    >
                      <Flex gap="20px">
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Flex>
                    </RadioGroup>
                  </Flex>
                </Stack>
              </Flex>

              {/* GENRE */}
              <hr style={{ border: "1px solid #424551" }} />
              <Flex direction="column" p="1rem">
                <Text color="#fff" fontSize="15px" fontWeight="700">
                  Genre
                </Text>
                <Flex direction="column" mt="1rem">
                  {genre.map((item, index) => (
                    <Checkbox
                      key={index}
                      color="#fff"
                      value={item.value}
                      isChecked={selectedGenres?.includes(item.value)}
                      onChange={() => handleGenreChange(item.value)}
                    >
                      {item.name}
                    </Checkbox>
                  ))}
                </Flex>
              </Flex>

              {/* Language */}
              <hr style={{ border: "1px solid #424551", margin: "10px 0" }} />
              <Flex direction="column" p="1rem">
                <Text color="#fff" fontSize="15px" fontWeight="700">
                  Language
                </Text>
                <Flex direction="column" mt="1rem">
                  {language.map((item, index) => (
                    <Checkbox
                      key={index}
                      color="#fff"
                      value={item.value}
                      isChecked={selectedLanguages?.includes(item.value)}
                      onChange={() => handleLanguageChange(item.value)}
                    >
                      {item.name}
                    </Checkbox>
                  ))}
                </Flex>
              </Flex>

              {/* Age Rating */}
              <hr style={{ border: "1px solid #424551", margin: "10px 0" }} />
              <Flex p="1rem" direction="column">
                <Text color="#fff" fontSize="15px" p="1rem" fontWeight="700">
                  Age Rating
                </Text>
                {ageRating.map((item, index) => (
                  <Checkbox
                    key={index}
                    color="#fff"
                    value={item.value}
                    isChecked={selectedAgeRating?.includes(item.value)}
                    onChange={() => handleAgeRatingChange(item.value)}
                  >
                    {item.name}
                  </Checkbox>
                ))}
              </Flex>

              {/* IMDB  Rating */}
              <hr style={{ border: "1px solid #424551", margin: "10px 0" }} />
              <Flex p="1rem" direction="column">
                <Text color="#fff" fontSize="15px" p="1rem" fontWeight="700">
                  IMDB Rating ({imbdRatingValues[0]} to {imbdRatingValues[1]})
                </Text>
                <Popover placement="top" closeOnBlur={false}>
                  <PopoverTrigger>
                    <Box>
                      <RangeSlider
                        aria-label={["min", "max"]}
                        defaultValue={[1, 10]}
                        min={1}
                        max={10}
                        onChange={handleImdbRatingChange}
                      >
                        <RangeSliderTrack>
                          <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb index={0} />
                        <RangeSliderThumb index={1} />
                      </RangeSlider>
                    </Box>
                  </PopoverTrigger>
                </Popover>
              </Flex>
              <Flex p="1rem" justify="center">
                <Button onClick={handleContentTypeCheck} w="70%">
                  {SellerFormReadLoading ? <Spinner /> : "Apply"}
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex w="80%" h="90vh" overflowY="scroll" direction="column">
            {SellerFormReadLoading ? (
              <Flex direction="column" gap="50px">
                {[1, 2, 3, 4, 5].map((index, item) => (
                  <Flex w="100%" h="100%" justify="center" align="center">
                    <Loader />
                  </Flex>
                ))}
              </Flex>
            ) : (
              <>
                {sellerRead?.data.length < 1 ? (
                  <Flex justify="center" align="center" h="100%" w="100%">
                    <Text fontSize="50px" fontWeight="600" color="white">
                      No Data Found
                    </Text>
                  </Flex>
                ) : (
                  <Flex gap="30px" direction="column">
                    {sellerRead?.data?.map((data, index) => (
                      <Carousel
                        props={{ title: data?.contentType, items: data?.items }}
                      />
                    ))}
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
