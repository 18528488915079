/*
 * IMPORTS
 */
import CreateWebStorage from 'redux-persist/lib/storage/createWebStorage' // Npm: redux persist local storage.


/*
 * OBJECTS
 */
const CreateNoopStorage = () => ({
  getItem(_key) {
    return Promise.resolve(null)
  },
  setItem(_key, value) {
    return Promise.resolve(value)
  },
  removeItem(_key) {
    return Promise.resolve()
  }
})


/*
 * EXPORTS
 */
export default 'undefined' === typeof window ? CreateNoopStorage() : CreateWebStorage('local')
