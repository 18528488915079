/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import _ from "underscore"; // Npm: Utility module.
import {
  loginBackground,
  loginCapa,
} from "./../../../Assets/images/loginPage/export.js";
import { logo3 } from "./../../../Assets/images/shucae/export.js";

import { useLazyQuery } from "@apollo/client";
import { IoMdArrowRoundBack } from "react-icons/io";
/*
 * CHAKRA IMPORT
 */
import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useMediaQuery,
  Spinner,
  useToast,
  Text,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import AdminLogin from "../signIn/__query__/index.signin.query.js";
import BuySellAcoountLogin from "./__query__/index.signin.query.js";

/*
 * REDUX IMPORT
 */
import { connect } from "react-redux";

function Index({ AccountUpdate }) {
  /*
   * STATE
   */
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const toast = useToast();
  const [BuySellAcoountLoginQuery, { loading: loadingBuySellAcoountLogin }] =
    useLazyQuery(BuySellAcoountLogin);

  /*
   * STYLE
   */
  const headerStyle = {
    color: "#A8A8A8",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "55.62px",
    letterSpacing: "0.28px",
  };
  /*
   * FUNCTION
   */

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      const response = await BuySellAcoountLoginQuery({
        variables: {
          email: email,
          password: password,
        },
      });

      const data = response.data.BuySellAcoountLogin;

      if (!_.isEmpty(data)) {
        if (data.message === "INVALID_CREDENTIALS") {
          setEmail("");
          setPassword("");
          toast({
            title: "Error",
            description: " Invalid Credentials .",
            status: "error",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
        } else if (data.message === "ACCOUNT_WITH_EMAIL_NOT_FOUND") {
          setEmail("");
          setPassword("");
          toast({
            title: "Error",
            description:
              " Account with this email not found Pls register first .",
            status: "error",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
          history.push("/signup");
        } else if (data.status === "LOGIN_SUCCESSFUL") {
          AccountUpdate({
            isUserLoggedIn: true,
            ...data,
          });

          setEmail("");
          setPassword("");
          toast({
            title: "Success",
            description: "Successfully logged in .",
            status: "success",
            duration: 5000, // Milliseconds
            isClosable: true,
          });
          history.push("/");
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred .",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      fontFamily="Poppins"
      bgImage={`url(${loginBackground})`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      fontSize={["clamp(13px, 1.5vw, 15px)"]}
      color="white"
      h="100vh"
    >
      <Flex w="100%" bg="rgba(0,0,0, 0.6)">
        <Flex
          flexDir="column"
          bgImage={`url(${loginCapa})`}
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          w="100%"
          p='20px'
        >
          <Flex>
            {!isLargerThan900 ? (
              <Flex align="center" >
                <NavLink to="/">
                  <IoMdArrowRoundBack size="20px" />
                </NavLink>
              </Flex>
            ) : null}
            <Flex
              pt={{ base: "2rem", md: "20px" }}
              w="100%"
              ml="20px"
              justify={{ base: "center", md: "start" }}
            >
              <NavLink to="/">
                <Image width="200px" src={logo3} />
              </NavLink>
            </Flex>
          </Flex>
          <Flex w="100%" h="100%" align="center" justify="center">
            <Flex direction="column" align="center">
              <Heading style={headerStyle} as="h2" size="lg" mb="4">
                Login to Shuace Films Buy and Sell
              </Heading>

              <form
                style={{ width: "100%" }}
                onSubmit={(e) => e.preventDefault()}
              >
                <FormControl id="email" mb="4">
                  <FormLabel>Email</FormLabel>
                  <Input
                    h="50px"
                    borderColor="#A8A8A8"
                    type="email"
                    placeholder="Enter your email"
                    color="white"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </FormControl>
                <FormControl id="password" mb="4">
                  <FormLabel>Password</FormLabel>
                  <InputGroup align="center">
                    <Input
                      h="50px"
                      borderColor="#A8A8A8"
                      color="white"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement h="100%" width="5rem">
                      <Button
                        color="black"
                        bg="blue.300"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="start"
                  maxW="100%"
                  mt="10px"
                >
                  <Text color="white" fontWeight="400" fontSize="14px">
                    New to Shucae Films Buy and Sell?
                    <NavLink to="/signup">
                      <Text
                        color="#C30280"
                        _hover={{ textDecor: "underline" }}
                        as="span"
                        ms="5px"
                        fontWeight="500"
                      >
                        Register here
                      </Text>
                    </NavLink>
                  </Text>
                </Flex>
                <Button
                  mt="20px"
                  w="100%"
                  colorScheme="blue"
                  onClick={handleLogin}
                  type="submit"
                >
                  {loadingBuySellAcoountLogin ? <Spinner /> : "Login"}
                </Button>
              </form>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * REDUX
 */
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(void 0, _MapDispatchToProps)(Index);
