/*
 * IMPORT
 */
import React, { useEffect, useState } from "react"; // NPM: React package.

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  Routes,
} from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state managment.
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.
import _ from "underscore";

/*
 * CONTEXT
 */
import {
  HashRouter,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

/*
 * COMPONENTS
 */
import SignIn from "./Views/Auth/signIn/SignInDefault.jsx";
import SignUp from "./Views/Auth/signup/index.jsx";
import HomePage from "./Views/User/website/HomePage/index";
import CartPage from "./Views/User/website/CartPage/index";
import MoviesPage from "./Views/User/website/MoviesPage/index";
import Context from "./Context/index.js";

/*
 * OJBECT
 */
const Index = ({ account }) => {
  const history = useHistory();

  const [context, setContext] = React.useState({});

  return (
    <Context.Provider value={{ context, setContext }}>
      <Toaster />
      <Router>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/movies-page" component={MoviesPage} />
          <Route exact path="/cart-page" component={CartPage} />
        </Switch>
      </Router>
    </Context.Provider>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (__state) => ({ account: __state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
