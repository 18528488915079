/*
 * IMPORT
 */
import React, { useRef, useState } from "react";
import "./index.css";
// Import required modules

/*
 * CHAKRA IMPORTS
 */
import { Flex, Text, Image } from "@chakra-ui/react";
import MovieDetail from "./MovieDetail";

/*
 * OBJECT
 */
export default function Index({ props }) {
  const { items, title, ribbon } = props;

  return (
    <Flex flexDir="column">
      <Text
        textTransform="capitalize"
        pb="5px"
        pl="30px"
        color="white"
        fontSize="28px"
      >
        {title}
      </Text>

      <Flex ml="10px" position="relative" maxW="100%">
        <Flex flexWrap="wrap" gap="10px" w="100%">
          {items?.map((movie, index) => (
            <CardComp key={index} item={movie} ribbon={ribbon} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * COMPONENT
 */
function CardComp({ onClick, item, index, ribbon }) {
  const cardRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  /*
   * STYLE
   */
  const titleStyle = {
    color: "#FFFFFF",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: "20px",
  };
  const subTitleStyle = {
    color: "#878992",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Flex
      ref={cardRef}
      mx="10px"
      borderRadius="15px"
      position="relative"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      transition="all 0.3s ease"
      onClick={toggleModal}
      cursor="pointer"
    >
      <Flex  w="260px" h="314px" className="card_image_div" borderRadius="15px">
        <Image
          src={item?.verticalThumbnailStoredAt}
          borderRadius="15px"
          className="movie_thumbnail"
          alt="image"
        />
      </Flex>
      <Flex p="10px" w="100%" direction="column">
        <Text style={titleStyle}>{item?.contentTitle}</Text>
        <Text textTransform="capitalize" style={subTitleStyle}>
          {new Date(item?.createdAt).getFullYear()} | {item?.genre}
        </Text>
      </Flex>

      <MovieDetail
        isOpen={showModal}
        onClose={toggleModal}
        movieDetails={item}
      />
    </Flex>
  );
}
