/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
  mutation MyMutation($email: String!, $fullName: String!, $password: String!) {
    BuySellAccountRegister(
      email: $email
      fullName: $fullName
      password: $password
    ) {
      message
      status
      token
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
