/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*
 * IMPORT
 */
import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import _ from "underscore"; // Npm: Utility module.
import {
  loginBackground,
  loginCapa,
} from "./../../../Assets/images/loginPage/export.js";
import { logo3 } from "./../../../Assets/images/shucae/export.js";
import { useMutation } from "@apollo/client";

import { IoMdArrowRoundBack } from "react-icons/io";
/*
 * CHAKRA IMPORT
 */
import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Button,
  useMediaQuery,
  Spinner,
  useToast,
  Text,
  PinInput,
  PinInputField,
  HStack,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

/*
 * QUERY
 */
import AdminLogin from "../signIn/__query__/index.signin.query.js";

/*
 * MUTATION
 */
import SendOTP from "./__mutation__/index.sendOTP.mutation.js";
import VerifyOTP from "./__mutation__/index.verifyOTP.mutation.js";
import BuySellAccountRegister from "./__mutation__/index.register.mutation.js";
/*
 * REDUX IMPORT
 */
import { connect } from "react-redux";

function Index({ AccountUpdate }) {
  /*
   * STATE
   */
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [email, setEmail] = useState("");
  const [otpSentStatus, setOtpSentStatus] = useState(false);
  const [otpVerifiedStatus, setOtpVerifiedStatus] = useState(false);
  const [time, setTime] = useState(60);
  const [disableResend, setDisableResend] = useState(false);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");

  const toast = useToast();

  const [SendOTPMutation, { loading: loadingSendOTP }] = useMutation(SendOTP);
  const [
    BuySellAccountRegisterMutation,
    { loading: loadingBuySellAccountRegister },
  ] = useMutation(BuySellAccountRegister);
  const [VerifyOTPMutation, { loading: loadingVerifyOTP }] =
    useMutation(VerifyOTP);

  /*
   * STYLE
   */
  const headerStyle = {
    color: "#A8A8A8",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "55.62px",
    letterSpacing: "0.28px",
  };
  /*
   * FUNCTION
   */
  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await SendOTPMutation({
        variables: {
          email: email,
        },
      });
      const data = response.data.SendOTP;

      if (!_.isEmpty(data) && data.status === "SENT_SUCCESSFULLY") {
        setOtpSentStatus(true);
        toast({
          title: "OTP Sent",
          description: "OTP Sent Successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTime(60);
        setDisableResend(true);
      } else if (data?.status === "EMAIL_EXIST") {
        toast({
          title: "Already Exist",
          description: "Email Already Registered!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setEmail("");
      }
    } catch (error) {
      setOtpSentStatus(false);
      toast({
        title: "Error",
        description: "Pls Try Again Later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await VerifyOTPMutation({
        variables: {
          OTP: pin,
        },
      });
      const data = response.data.VerifyOTP;

      if (!_.isEmpty(data) && data.status === "OTP_VERIFIED") {
        setOtpVerifiedStatus(true);
        setOtpSentStatus(false);
        toast({
          title: "OTP Verified",
          description: "OTP Verified Successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else if (data?.status === "INVALID_OTP") {
        toast({
          title: "Error",
          description: "Invalid OTP!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setOtpVerifiedStatus(false);
      toast({
        title: "Error",
        description: "Pls Try Again Later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    try {
      const response = await BuySellAccountRegisterMutation({
        variables: {
          email: email,
          fullName: name,
          password: password,
        },
      });
      const data = response.data.BuySellAccountRegister;

      if (!_.isEmpty(data) && data.status === "CREATED_SUCCESSFULLY") {
        AccountUpdate({
          isUserLoggedIn: true,
          ...data,
        });

        setOtpVerifiedStatus(true);
        toast({
          title: "User Registered",
          description: "User Registered successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push("/");
      }
    } catch (error) {
      setOtpVerifiedStatus(false);
      toast({
        title: "Error",
        description: "Pls Try Again Later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  function handleChange(value) {
    setPin(value);
  }

  useEffect(() => {
    let timer;
    if (time > 0) {
      timer = setTimeout(() => setTime(time - 1), 1000);
    } else {
      setDisableResend(true);
    }
    return () => clearTimeout(timer);
  }, [time]);

  return (
    <Flex
      fontFamily="Poppins"
      bgImage={`url(${loginBackground})`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      fontSize={["clamp(13px, 1.5vw, 15px)"]}
      color="white"
      h="100vh"
      
    >
      <Flex p='20px' w="100%" bg="rgba(0,0,0, 0.6)">
        <Flex
          flexDir="column"
          bgImage={`url(${loginCapa})`}
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          w="100%"
        >
          <Flex>
            {!isLargerThan900 ? (
              <Flex align="center">
                <NavLink to="/">
                  <IoMdArrowRoundBack size="20px" />
                </NavLink>
              </Flex>
            ) : null}
            <Flex
              pt={{ base: "2rem", md: "20px" }}
              w="100%"
              ml="20px"
              justify={{ base: "center", md: "start" }}
            >
              <NavLink to="/">
                <Image width="200px" src={logo3} />
              </NavLink>
            </Flex>
          </Flex>
          <Flex w="100%" h="100%" align="center" justify="center">
            <Flex direction="column" align="center">
              <Heading style={headerStyle} as="h2" size="lg" mb="4">
                Register to Shuace Films Buy and Sell
              </Heading>
              {otpVerifiedStatus ? (
                <FormControl id="name" mb="4" isRequired>
                  <FormLabel>Full Name</FormLabel>
                  <Input
                    h="50px"
                    borderColor="#A8A8A8"
                    type="name"
                    placeholder="Enter your name"
                    color="white"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              ) : (
                <></>
              )}

              <FormControl id="email" mb="4" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  h="50px"
                  isDisabled={otpSentStatus || otpVerifiedStatus}
                  borderColor="#A8A8A8"
                  type="email"
                  placeholder="Enter your email"
                  color="white"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              {otpVerifiedStatus ? (
                <FormControl id="password" mb="4" isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup align="center">
                    <Input
                      h="50px"
                      borderColor="#A8A8A8"
                      color="white"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement h="100%" width="5rem">
                      <Button
                        color="black"
                        bg="blue.300"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              ) : (
                <></>
              )}
              {otpSentStatus ? (
                <FormControl isRequired>
                  <FormLabel>OTP</FormLabel>
                  <HStack borderColor="#A8A8A8">
                    <PinInput size="lg" otp value={pin} onChange={handleChange}>
                      <PinInputField color="white" />
                      <PinInputField color="white" />
                      <PinInputField color="white" />
                      <PinInputField color="white" />
                    </PinInput>
                  </HStack>
                </FormControl>
              ) : (
                <></>
              )}

              <Flex w="100%" mt="10px" justify="space-between">
                <Text color="white" fontWeight="400" fontSize="14px">
                  Already Registered?
                  <NavLink to="/signin">
                    <Text
                      color="#C30280"
                      _hover={{ textDecor: "underline" }}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                    >
                      Login
                    </Text>
                  </NavLink>
                </Text>
                {/* {otpSentStatus && (
                  <Flex color="white" fontWeight="400" fontSize="14px">
                    <Text
                      cursor={disableResend ? "not-allowed" : "pointer"}
                      onClick={disableResend ? null : handleSendOTP}
                      style={{ pointerEvents: disableResend ? "none" : "auto" }}
                    >
                      {loadingSendOTP ? <Spinner /> : "Resend OTP in"}
                    </Text>
                    <Text
                      color="#C30280"
                      _hover={{ textDecor: "underline" }}
                      as="span"
                      ms="5px"
                      fontWeight="500"
                    >
                      {`${time} Seconds`}
                    </Text>
                  </Flex>
                )} */}
              </Flex>
              {otpVerifiedStatus ? (
                <Button
                  mt="20px"
                  w="100%"
                  colorScheme="blue"
                  onClick={handleRegistration}
                  type="submit"
                >
                  {loadingBuySellAccountRegister ? <Spinner /> : "Register"}
                </Button>
              ) : (
                <Button
                  mt="20px"
                  w="100%"
                  colorScheme="blue"
                  isDisabled={email === "" ? true : false}
                  onClick={otpSentStatus ? handleVerifyOTP : handleSendOTP}
                  type="submit"
                >
                  {otpSentStatus ? (
                    loadingVerifyOTP ? (
                      <Spinner />
                    ) : (
                      "Verify OTP"
                    )
                  ) : loadingSendOTP ? (
                    <Spinner />
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

/*
 * REDUX
 */
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(void 0, _MapDispatchToProps)(Index);
