/*
 * IMPORT
 */
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { useHistory } from "react-router-dom";

// import SellSection from "./SellSection";
// import BuySection from "./BuySection";
import MovieSection from "./MovieSection";
import SellerForm from "../../../../components/SellerForm/SellerForm";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";

/*
 * CHAKRA IMPORTS
 */
import { Box, Image, Flex, Text, Button } from "@chakra-ui/react";

/*
 * QUERY
 */
import RandomSellerWallPaper from "./__query__/index.randomWallpaper.query";
import { useQuery } from "@apollo/client";

/*
 * OBJECT
 */
function HomePage() {
  /*
   * STATE
   */
  const history = useHistory();

  const _RandomSellerWallPaper = useQuery(RandomSellerWallPaper);

  /*
   * STYLE
   */
  const buybutton = {
    borderRadius: "8px",
    fontFamily: "Poppins",

    fontWeight: "500",
    lineHeight: "31.74px",
    letterSpacing: "-0.2px",
    backgroundColor: "#052DB0",
    color: "#D9D9D9",
    cursor: "pointer",
  };

  const swiperCardStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "18px",
    fontSize: "22px",
    fontWeight: "bold",
    color: "#fff",
  };

  const handleBuyContent = () => {
    history.push("/movies-page");
  };

  /*
   * FUNCTION
   */
  return (
    <Box bg="#00020A">
      <Navbar />
      <Flex gap="20px" mb='20px' direction="column">
        <Flex
          align="center"
          justify={{ base: "center", md: "space-evenly" }}
          pt={{ base: "6rem", md: "10rem" }}
          direction={{ base: "column-reverse", md: "row" }}
          position="relative"
        >
          <Flex
            ml={{ base: "0px", md: "3rem" }}
            gap="2rem"
            justify="center"
            direction="column"
          >
            <Flex
              fontFamily="Montserrat"
              fontSize={{ base: "22px", md: "55.11px" }}
              fontWeight="700"
              lineHeight={{ base: "28px", md: "45.87px" }}
              letterSpacing="1.653px"
              direction="column"
              color="#FFFFFF"
              gap={{ base: "0.1rem", md: "0.5rem" }}
            >
              <Text textAlign={{ base: "center", md: "left" }}>
                Unlock the Cinematic Marketplace –
              </Text>
              <Text
                bg="linear-gradient(267.33deg, #C30280 0.92%, #052DB0 100.27%)"
                bgClip="text"
                lineHeight={{ base: "28px", md: "60px" }}
                textAlign={{ base: "center", md: "left" }}
              >
                Buy, Sell, and Share
              </Text>
              <Text textAlign={{ base: "center", md: "left" }}>
                Your Movie Collection!
              </Text>
            </Flex>

            <Flex
              color="rgba(217, 217, 217, 1)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontWeight="400"
              lineHeight={{ base: "17.83px", md: "26.12px" }}
              letterSpacing="0.16px"
              direction="column"
              textAlign={{ base: "center", md: "left" }}
            >
              <Text>
                Create new opportunitites in seasoned markets or new territories
              </Text>
              <Text>when you list your content on the global marketplace.</Text>
            </Flex>

            <Flex
              zIndex="2"
              gap="1rem"
              justifyContent={{ base: "center", md: "flex-start" }}
            >
              <Button
                onClick={handleBuyContent}
                style={buybutton}
                width={{ base: "110.3px", md: "170px" }}
                height={{ base: "31.5px", md: "58px" }}
                fontSize={{ base: "10.859px", md: "20px" }}
                ml={{ base: "5rem", md: "0" }}
                _hover={{ bg: "#052DB0" }}
              >
                Buy Content
              </Button>
              <SellerForm />
            </Flex>

            {/* <CreatorForm /> */}
          </Flex>

        <Flex
            h="800px"
            left="0"
            top="0"
            opacity="0.3"
            w="100%"
            position="absolute"
            zIndex="0"
            bg="radial-gradient(41.5% 41.5% at 59.19% 49%, rgba(25, 118, 210, 0.6) 0%, rgba(117, 81, 255, 0) 100%)"
          ></Flex>
         <Flex
            h="800px"
            right="0"
            top="0"
            opacity="0.3"
            w="100%"
            position="absolute"
            zIndex="0"
            bg="radial-gradient(41.25% 41.25% at 63.41% 47.47%, rgba(195, 2, 128, 0.6) 0%, rgba(5, 45, 176, 0.012) 100%)"
          ></Flex>

          <Flex
            justify="center"
            align="center"
            w={{ base: "90%", xl: "50%" }}
            zIndex="2"
          >
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              style={{ width: "270px", height: "375px" }}
            >
              {[1, 2, 3, 4]?.map((item, index) => (
                <SwiperSlide key={index} style={swiperCardStyle}>
                  <Image
                    objectFit="fill"
                    src={item?.verticalThumbnailStoredAt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Flex>
        </Flex>

        {/* <BuySection />
        <SellSection /> */}
        <MovieSection />
      </Flex>
      <Footer />
    </Box>
  );
}

/*
 * EXPORT
 */
export default HomePage;
