// MovieDetail.js

/*
 * IMPORT
 */
import React, { useEffect, useState } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import ReactPlayer from "react-player";
import { connect } from "react-redux";

/*
 * CHAKRA UI IMPORT
 */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
  Flex,
  Box,
  useToast,
  Spinner,
} from "@chakra-ui/react";

/*
 * MUTATION
 */
import ItemAddToCart from "../__mutation__/index.addToCart.mutation";

/*
 * QUERY
 */
import CartStatus from "../__query__/index.checkMovieStatus.query";
import { useMutation, useLazyQuery } from "@apollo/client";

/*
 * OBJECT
 */
const MovieDetail = ({ isOpen, onClose, movieDetails, account }) => {
  /*
   * STATE
   */
  const toast = useToast();
  const history = useHistory();
  const [cartCheck, setCartCheck] = useState("");

  const [ItemAddToCartMutation, { loading: ItemAddToCartLoading }] =
    useMutation(ItemAddToCart, {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    });

  const [CartStatusQuery, { loading: CartStatusLoading }] = useLazyQuery(
    CartStatus,
    {
      context: {
        headers: {
          "l-authorization": account.token,
        },
      },
    }
  );

  const textStyle = {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21.54px",
  };

  /*
   * FUNCTION
   */

  function formatDuration(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }
  const handleAddToCart = async () => {
    try {
      const response = await ItemAddToCartMutation({
        variables: {
          sellFormId: movieDetails?.id,
        },
      });

      const data = response.data.ItemAddToCart;

      if (!_.isEmpty(data)) {
        if (data?.status === "CART_SUCCESSFULLY_ADDED") {
          toast({
            title: "Item Added",
            description: "Item Added successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/cart-page");
        } else if (data?.status === "ITEM_ALREADY_IN_CART") {
          toast({
            title: "Item Already Added",
            description: "Item is already in cart!",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (data?.status === "ITEM_ALREADY_BOUGHT") {
          toast({
            title: "Item Already Bought",
            description: "This Item is already bought by you!",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };
  const handleCartStatusCheck = async () => {
    try {
      const response = await CartStatusQuery({
        variables: {
          sellFormId: movieDetails?.id,
        },
      });

      const data = response.data.CartStatus;

      if (!_.isEmpty(data)) {
        setCartCheck(data?.status);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred Pls Try Again Later.",
        status: "error",
        duration: 5000, // Milliseconds
        isClosable: true,
      });
    }
  };

  function handleLoginCheck() {
    if (account.isUserLoggedIn) {
      handleAddToCart();
    } else {
      history.push("/signin");
    }
  }

  useEffect(() => {
    if (isOpen) {
      handleCartStatusCheck();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody borderRadius="5px" bg="rgba(0, 2, 10, 1)" padding="0px">
          <Box
            borderRadius="10px"
            position="relative"
            overflow="hidden"
            bg="black"
            h="30rem"
          >
            <Flex position="absolute" w="100%" h="100%">
              <ReactPlayer
                light={movieDetails?.thumbnailStoredAt}
                url={movieDetails?.contentLink}
                onError={(e) => console.log(e, "error")}
                width="100%"
                height="100%"
                controls
                playing
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                style={{ objectFit: "cover" }}
              />
            </Flex>
            <Flex
              position="absolute"
              top="0"
              right="0"
              h="70px"
              w="70px"
              alignItems="center"
            >
              <IoCloseCircleSharp
                color="#979898"
                cursor="pointer"
                onClick={onClose}
                size="50px"
                style={{ position: "absolute", top: "10px", right: "10px" }}
              />
            </Flex>
          </Box>
          <Flex
            direction="column"
            align="center"
            bg="rgba(0, 2, 10, 1)"
            justify="center"
            gap="10px"
          >
            <Flex
              m="15px 0px 0px 0px"
              borderRadius="10px"
              bg="rgba(39, 39, 39, 1)"
              w="95%"
              direction="column"
              justify="space-between"
              p="20px"
              gap="25px"
            >
              <Text
                color="rgba(255, 255, 255, 1)"
                fontFamily="Roboto"
                fontSize="20px"
                fontWeight="700"
                lineHeight="21.54px"
                letterSpacing="0.2px"
              >
                About Movie
              </Text>
              <Flex w="100%" justify="space-between">
                <Flex justify="center" gap="30px" w="70%" direction="column">
                  <Text
                    color="rgba(255, 255, 255, 1)"
                    fontFamily="Roboto"
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="21.54px"
                    letterSpacing="0.2px"
                  >
                    {movieDetails?.contentTitle}
                  </Text>
                  <Flex align="center" gap="8px">
                    <Text
                      color="rgba(69, 211, 105, 1)"
                      fontFamily="Roboto"
                      fontSize="19px"
                      fontWeight="700"
                      lineHeight="21.54px"
                      letterSpacing="-0.36px"
                    >
                      {new Date(movieDetails?.createdAt)?.getFullYear()}
                    </Text>
                    <Text
                      color="rgba(186, 186, 186, 1)"
                      fontFamily="Roboto"
                      fontSize="19px"
                      fontWeight="400"
                      lineHeight="21.54px"
                      letterSpacing="-0.36px"
                    >
                      {formatDuration(movieDetails?.duration)}
                    </Text>
                    <Flex
                      borderRadius="3px"
                      justify="center"
                      align="center"
                      w="32px"
                      h="19px"
                      border="1px solid rgba(116, 116, 116, 1)"
                      color="rgba(255, 255, 255, 1)"
                      fontFamily="Roboto"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="21.54px"
                    >
                      HD
                    </Flex>
                  </Flex>
                  <Flex color="white" align="center" gap="5px">
                    <Flex
                      w="70px"
                      h="20px"
                      justify="center"
                      align="center"
                      border="1px solid rgba(116, 116, 116, 1)"
                    >
                      {`${movieDetails?.ageRating === "18" ? "A" : "U/A"} ${
                        movieDetails?.ageRating
                      }+`}
                    </Flex>
                    {movieDetails?.ageRating === "18" ? (
                      <Text>
                        <span style={{ textTransform: "capitalize" }}>
                          gore, language, mature themes, violence
                        </span>
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Text style={textStyle}>{movieDetails?.plotSummary}</Text>
                </Flex>
                <Flex gap="20px" style={textStyle} w="30%" direction="column">
                  <Button
                    w="212px"
                    h="44px"
                    borderRadius="8px"
                    bg={
                      cartCheck === "CART_ADDED"
                        ? "green"
                        : cartCheck === "CART_BOUGHT"
                        ? "red.300"
                        : "#052DB0"
                    }
                    _hover={{
                      bg:
                        cartCheck === "CART_ADDED"
                          ? "green"
                          : cartCheck === "CART_BOUGHT"
                          ? "red.300"
                          : "#052DB0",
                    }}
                    color="white"
                    onClick={handleLoginCheck}
                    isDisabled={
                      cartCheck === "CART_ADDED" || cartCheck === "CART_BOUGHT"
                    }
                  >
                    {ItemAddToCartLoading ? (
                      <Spinner />
                    ) : cartCheck === "CART_ADDED" ? (
                      "Already in cart"
                    ) : cartCheck === "CART_BOUGHT" ? (
                      "Already Bought"
                    ) : (
                      "Add to cart"
                    )}
                  </Button>

                  <Text>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Cast:
                    </span>{" "}
                    {movieDetails?.cast?.map((item) => (
                      <span>{item.name}, </span>
                    ))}
                  </Text>
                  <Text style={{ textTransform: "capitalize" }}>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Genre:{" "}
                    </span>
                    {movieDetails?.genre}
                  </Text>
                  <Text style={{ textTransform: "capitalize" }}>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Language:{" "}
                    </span>
                    {movieDetails?.language}
                  </Text>
                  <Text style={{ textTransform: "capitalize" }}>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      Music:{" "}
                    </span>
                    {movieDetails?.music}
                  </Text>
                  <Text style={{ textTransform: "capitalize" }}>
                    <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                      IMDB Rating:{" "}
                    </span>
                    {movieDetails?.imdbRating}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              mb="10px"
              borderRadius="10px"
              bg="rgba(39, 39, 39, 1)"
              w="95%"
              p="20px"
              direction="column"
              wrap="wrap"
              gap="20px"
              color="white"
            >
              <Flex
                p="0px 20px 0px 20px"
                align="left"
                justify="space-between"
                w="100%"
              >
                <Text style={{ textTransform: "capitalize" }}>
                  <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                    Digital Rights :{" "}
                  </span>
                  {movieDetails?.digitalRight ? "Yes" : "No"}
                </Text>
                <Text style={{ textTransform: "capitalize" }}>
                  <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                    Satellite Rights :{" "}
                  </span>
                  {movieDetails?.satelliteRight ? "Yes" : "No"}
                </Text>
                <Text style={{ textTransform: "capitalize" }}>
                  <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                    Airborne Rights :{" "}
                  </span>
                  {movieDetails?.airborneRight ? "Yes" : "No"}
                </Text>
              </Flex>
              <Flex
                p="0px 20px 0px 20px"
                align="left"
                justifyContent="space-between"
                w="100%"
              >
                <Text style={{ textTransform: "capitalize" }}>
                  <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                    Exclusive:{" "}
                  </span>
                  {movieDetails?.exclusive ? "Yes" : "No"}
                </Text>

                <Text style={{ textTransform: "capitalize" }}>
                  <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                    Territory :{" "}
                  </span>
                  {movieDetails?.territorySelect
                    ? movieDetails?.territorySelect
                    : "None"}
                </Text>

                <Text style={{ textTransform: "capitalize" }}>
                  <span style={{ color: "rgba(115, 115, 115, 1)" }}>
                    Country :{" "}
                  </span>
                  {movieDetails?.country ? movieDetails?.country : "None"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(MovieDetail);
