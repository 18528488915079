/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $ageRating: String
    $agreeTerms: Boolean
    $airborneRight: Boolean
    $allRights: Boolean
    $cast: [JSON]
    $country: String
    $contentLink: String
    $contentTitle: String
    $contentType: String
    $digitalRight: Boolean
    $directorName: String
    $duration: Float
    $email: String
    $exclusive: Boolean
    $genre: String
    $haveCopyrights: Boolean
    $imdbRating: Float
    $language: String
    $lastName: String
    $music: String
    $firstName: String
    $nonExclusive: Boolean
    $phone: String
    $plotSummary: String
    $producer: String
    $satelliteRight: Boolean
    $storyWriter: String
    $territory: Boolean
    $territorySelect: String
    $thumbnailStoredAt: JSON
    $verticalThumbnailStoredAt: JSON
  ) {
    SellerFormCreate(
      ageRating: $ageRating
      agreeTerms: $agreeTerms
      airborneRight: $airborneRight
      allRights: $allRights
      cast: $cast
      country: $country
      contentLink: $contentLink
      contentTitle: $contentTitle
      contentType: $contentType
      digitalRight: $digitalRight
      directorName: $directorName
      duration: $duration
      email: $email
      exclusive: $exclusive
      genre: $genre
      haveCopyrights: $haveCopyrights
      imdbRating: $imdbRating
      language: $language
      lastName: $lastName
      music: $music
      firstName: $firstName
      nonExclusive: $nonExclusive
      phone: $phone
      plotSummary: $plotSummary
      producer: $producer
      satelliteRight: $satelliteRight
      storyWriter: $storyWriter
      territory: $territory
      territorySelect: $territorySelect
      thumbnailStoredAt: $thumbnailStoredAt
      verticalThumbnailStoredAt: $verticalThumbnailStoredAt
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
