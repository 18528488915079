/*
 * IMPORT
 */
import { connect } from "react-redux"; // NPM: React-redux library.

/*
 * CHAKRA UI IMPORT
 */
import {
  Box,
  Flex,
  Spacer,
  Button,
  Link,
  IconButton,
  useDisclosure,
  Text,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Image,
  Avatar,
  DrawerCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HamburgerIcon, CloseIcon, PlusSquareIcon } from "@chakra-ui/icons";

import { NavLink, useLocation } from "react-router-dom";
import "./../../index.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logo1, logo2, logo3 } from "../../Assets/images/shucae/export";

const Navbar = ({ account, AccountLogout }) => {
  const location = useLocation();
  const routeName = location.pathname;
  const history = useHistory();
  const [isLargerThan900] = useMediaQuery("(min-width: 1000px)");
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const [isLargerThan1200] = useMediaQuery("(min-width: 1200px)");
  const [openNavbar, setOpenNavbar] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fixIt, setFixIt] = React.useState(false);
  const firstField = React.useRef();
  const [bgColor, setBgColor] = useState(false);

  const navbarLinksData = [{ name: "Home", takeTo: "/" }];

  function _handleNavbar() {
    setOpenNavbar(!openNavbar);
  }

  const [hasShadow, setHasShadow] = React.useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasShadow(true);
    } else {
      setHasShadow(false);
    }
  };

  function _handleLogout() {
    AccountLogout({
      isUserLoggedIn: false,
    });
    history.push("/signin");
  }

  function handleLoginCheck() {
    if (account.isUserLoggedIn) {
      history.push("/cart-page");
    } else {
      history.push("/signin");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <Bonus />  */}
      <Flex
        fontFamily="Montserrat"
        id="navbar"
        as="nav"
        align="center"
        justify="space-between"
        h="100px"
        w="100%"
        color="white"
        bg="linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.76) 30.6%, rgba(0, 0, 0, 0.02) 90.12%)"
        zIndex="5"
        position="fixed"
      >
        {/* HL ---------------------------------- web navbar ------------------------------------------- */}
        <Flex
          display={!isLargerThan900 ? "none" : "flex"}
          w="95%"
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <NavLink to="/">
            <Flex alignItems="center" gap="10px">
              <Image width="200px" src={logo3} />
            </Flex>
          </NavLink>

          <Flex
            px="30px"
            justifyContent="space-evenly"
            maxW="600px"
            width="100%"
          >
            {navbarLinksData?.map((item) => (
              <Flex
                fontWeight="500"
                color={location.pathname === item.takeTo ? "white" : "white"}
                alignItems="center"
              >
                <NavLink to={item.takeTo}>{item.name}</NavLink>
              </Flex>
            ))}
          </Flex>

          <Flex color="white" gap="10px" alignItems="center">
            <Button
              fontFamily="Roboto"
              fontWeight="400"
              fontSize="12px"
              lineHeight="32.83px"
              letterSpacing="0.12px"
              w="114px"
              h="30px"
              borderRadius="5px"
              onClick={handleLoginCheck}
              bg="linear-gradient(93.55deg, #052DB0 5.03%, #C30280 95.67%),
            linear-gradient(94.66deg, #052DB0 -1.16%, #C30280 96.99%)"
              _hover={{
                bg: `linear-gradient(93.55deg, #052DB0 5.03%, #C30280 95.67%),
                linear-gradient(94.66deg, #052DB0 -1.16%, #C30280 96.99%)`,
              }}
            >
              My Cart
            </Button>
            {account?.isUserLoggedIn ? (
              <Text
                color="white"
                cursor="pointer"
                onClick={() => _handleLogout()}
              >
                Logout
              </Text>
            ) : (
              <Text cursor="pointer" onClick={() => history.push("/SignIn")}>
                Login
              </Text>
            )}
          </Flex>
        </Flex>

        {/* HL ---------------------------------- mobile navbar ------------------------------------------- */}

        <Flex
          display={isLargerThan900 ? "none" : "flex"}
          w="100%"
          mx="auto"
          alignItems="center"
        >
          <Box>
            <NavLink to="/">
              <Image width="200px" src={logo3} alt="" />
            </NavLink>
          </Box>
        </Flex>

        <Drawer
          isOpen={openNavbar}
          placement="right"
          initialFocusRef={firstField}
          onClose={onClose}
          width="80vw"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader
              py="10px"
              px="10px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Menu{" "}
              <Button
                color="#FF9C94"
                onClick={() => _handleNavbar()}
                bg="#FDF2ED"
                w="2.5rem"
                h="2.5rem"
                borderRadius="50%"
                border="none"
                outline="none"
              >
                <CloseIcon style={{ fontSize: "16px" }} />
              </Button>
            </DrawerHeader>

            <DrawerBody border="2px solid red">
              <Flex flexDirection="column" gap="2rem">
                {navbarLinksData?.map((item) => (
                  <Flex
                    _hover={{ color: "#ff896c" }}
                    fontWeight="500"
                    alignItems="center"
                  >
                    <NavLink
                      color={
                        location.pathname === item.takeTo
                          ? "#ff896c"
                          : "#263238"
                      }
                      to={item.takeTo}
                    >
                      {item.name}
                    </NavLink>
                  </Flex>
                ))}
              </Flex>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px"></DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};

const __MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, __MapDispatchToProps)(Navbar);
