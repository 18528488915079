/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
query MyQuery($email: String!, $password: String!) {
  BuySellAcoountLogin(email: $email, password: $password) {
    message
    status
    token
    fullName
    email
  }
}
`;

/*
 * EXPORTS
 */
export default Index;
