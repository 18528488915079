/*
 * IMPORT
 */
import React, { useState } from "react";
import Navbar from "../../../../components/LandingNavbar/index";
import Footer from "../../../../components/LandingFooter/index";
import { connect } from "react-redux";

/*
 * CHAKRA IMPORTS
 */
import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Text,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import CartVideos from "components/CartVideos/CartVideos";
import BuyerForm from "components/BuyerForm/BuyerForm";

import CartItemRead from "./__query__/index.cartItemRead.query";
import { useQuery } from "@apollo/client";

/*
 * OBJECT
 */
function CartPage({ account }) {
  /*
   * States
   */

  const [tabState, setTabState] = useState(true);

  const _CartItemRead = useQuery(CartItemRead, {
    context: {
      headers: {
        "l-authorization": account.token,
      },
    },
  });

  const tabStyle = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "55.62px",
    letterSpacing: "0.32px",
  };


  /*
   * Functions
   */

  return (
    <Box bg="#00020A">
      <Navbar />
      <Box
        bg="linear-gradient(110.99deg, rgba(5, 45, 176, 0.2) 0%, rgba(195, 2, 128, 0.2) 100%)"
        w="100%"
        h="100vh"
      >
        <Flex w="100%" h="100px"></Flex>
        <Flex w="100%">
          <Flex overflow="scroll" direction="column" w="100%">
            <Tabs width="100%">
              <TabList
                width="100%"
                gap="3rem"
                padding="0px"
                style={tabStyle}
                borderColor="none"
                borderBottom="1px solid #505050"
              >
                <Tab
                  ml="3rem"
                  padding="0px"
                  _selected={{ color: "#FFFFFF" }}
                  color="#878992"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => setTabState(true)}
                >
                  Cart
                </Tab>
              </TabList>
              <TabIndicator
                mt="-2px"
                height="3px"
                bg="#FFFFFF"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel w="100%" h="100%" padding="0px">
                  {_CartItemRead?.data?.CartItemRead?.data?.[0]?.length < 1 ? (
                    <Flex h="50vh" align="center" justify="center">
                      <Text fontSize="60px" fontWeight="600" color="white">
                        No Item found in your cart
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      <Flex
                        overflowY="auto"
                        h="calc(100vh - 300px)"
                        p="35px 24px"
                        direction="column"
                        gap="1rem"
                      >
                        <CartVideos data={_CartItemRead} />
                      </Flex>
                      <BuyerForm data={_CartItemRead} />
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(CartPage);
