/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery {
    CartItemRead {
        message
        status
        data

    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
