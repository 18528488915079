/*
 * IMPORT
 */
import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { FaArrowRightLong } from "react-icons/fa6";

/*
 * CHAKRA IMPORTS
 */
import { Flex, Text, Image, useDisclosure } from "@chakra-ui/react";
import MovieDetail from "./MovieDetail";

/*
 * OBJECT
 */
export default function Index({ props }) {
  /*
   * STATE
   */
  const { items, title, ribbon } = props;
  const history = useHistory();
  const [slidesPerView, setSlidesPerView] = useState(5);

  /*
   * USEEFFECT
   */
  useEffect(() => {
    const handleResize = () => {

      if (window.innerWidth < 768) {
        setSlidesPerView(2); 
      } else if (window.innerWidth > 767 && window.innerWidth < 1025) {
        setSlidesPerView(4); 
      } else if (window.innerWidth > 767 && window.innerWidth < 1400) {
        setSlidesPerView(4); 
      } else {
        setSlidesPerView(5); 
      }
    };


    window.addEventListener("resize", handleResize);


    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex mb="2rem" flexDir="column">
      <Flex align="center" justifyContent="space-between">
        <Text textTransform="capitalize" pb="5px" color="white" fontSize="28px">
          {title}
        </Text>
        <Flex
          cursor="pointer"
          mr="1rem"
          color="white"
          onClick={() => history.push(`/movies-page`)}
        >
          <FaArrowRightLong size="25px" />
        </Flex>
      </Flex>

      <Flex w="100%">
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={10}
          centeredSlides={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          style={{
            width: "100%",
            alignItems: "center",
            overflow: "visible",
            padding: "0px",
            margin: "0px",
          }}
        >
          {items?.map((movie, index) => (
            <SwiperSlide
              key={index}
              style={{
                width: "260px",
              }}
            >
              <CardComp key={index} item={movie} ribbon={ribbon} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
    </Flex>
  );
}

/*
 * COMPONENT
 */
function CardComp({ onClick, item, index, ribbon }) {
  /*
   * STATE
   */
  const cardRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  /*
   * STYLE
   */
  const titleStyle = {
    color: "#FFFFFF",
    fontWeight: "700",
    fontFamily: "Montserrat",
    fontSize: "20px",
  };
  const subTitleStyle = {
    color: "#878992",
    fontWeight: "600",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "-0.32px",
  };

  /*
   * FUNCTION
   */
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Flex
      ref={cardRef}
      borderRadius="15px"
      flexDir="column"
      onClick={toggleModal}
      cursor="pointer"
    >
      <Flex w="260px" h="314px" className="card_image_div" borderRadius="15px">
        <Image
          src={item?.verticalThumbnailStoredAt}
          borderRadius="10px"
          className="movie_thumbnail"
          alt="image"
        />
      </Flex>
      <Flex p="10px 0px 0px 0px" w="100%" direction="column">
        <Text style={titleStyle}>{item?.contentTitle}</Text>
        <Text textTransform="capitalize" style={subTitleStyle}>
          {new Date(item?.createdAt).getFullYear()} | {item?.genre}
        </Text>
      </Flex>

      <MovieDetail
        isOpen={showModal}
        onClose={toggleModal}
        movieDetails={item}
      />
    </Flex>
  );
}
