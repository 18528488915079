/*
 * PACKAGES
 */
export const _initialState = {
    connector: '',
  };
  
  /*
   * OBJECTS
   */
  const _ConnectorReducer = (__prevState = _initialState, __action) => {
    // console.log(__action, _initialState, __prevState)
    /*
     * Switch case for handling actions on
     * account store.
     */
    switch (__action.type) {
      case "SET_CONNECTOR":
        // Return combined state.
        return {
          ...__prevState,
          ...__action.Connector,
        };
    
      default:
        // Return old state.
        return __prevState;
    }
  }
  
  /*
   * EXPORTS
   */
  export default _ConnectorReducer;
  