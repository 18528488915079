/*
 * IMPORTS
 */
import Carousel from "./Components/carousel";
import { connect } from "react-redux"; // NPM: React-redux library.
import { useHistory } from "react-router-dom";
import Loader from "../../../../../components/Loader/loader";
/*
 * CHAKRA IMPORTS
 */
import { Box, Text, Flex, Button } from "@chakra-ui/react";

/*
 * QUERY
 */
import SellerFormRead from "../__query__/index.getSellerMovies.query";
import { useQuery } from "@apollo/client";

/*
 * OBJECT
 */
function Index({ account }) {
  /*
   * STATE
   */
  const history = useHistory();

  const _SellerFormRead = useQuery(SellerFormRead);
  console.log(
    _SellerFormRead?.data?.SellerFormRead?.data,
    "_SellerFormRead?.data?.SellerFormRead?.data"
  );

  return (
    <Box
      m={{ base: "2px", md: "5rem 0px 5rem 3rem" }}
      fontFamily="Poppins"
      fontSize={["clamp(12px, 1.5vw, 15px)"]}
      fontWeight="500"
      color="#263238"
    >
      {_SellerFormRead?.loading ? (
        <Flex direction="column" gap="50px">
          {[1, 2, 3, 4, 5].map((index, item) => (
            <Flex w="100%" h="100%" justify="center" align="center">
              <Loader />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Box>
          {_SellerFormRead?.data?.SellerFormRead?.data?.map((data, index) => (
            <Carousel
              props={{ title: data?.contentType, items: data?.items }}
            />
          ))}
        </Box>
      )}

      {_SellerFormRead?.data?.SellerFormRead?.data?.length > 0 ? (
        <Flex color={"#fff"} m="1rem auto" justify={"center"}>
          <Button
            onClick={() => history.push("/movies-page")}
            w="200px"
            colorScheme="blue"
          >
            See All
          </Button>
        </Flex>
      ) : (
        <Flex
          color={"#fff"}
          fontSize={{ base: "20px", md: "55px" }}
          m="1rem auto"
          justify="center"
        >
          <Text>NO CONTENT AVAILABLE</Text>
        </Flex>
      )}
    </Box>
  );
}

const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

const _MapStateToProps = (state) => ({
  account: state.Account,
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
