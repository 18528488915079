/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $cartId: [ID]
    $companyName: String
    $companyUrl: String
    $preferredContent: String
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
  ) {
    BuyFormCreate(
      cartId: $cartId
      companyName: $companyName
      companyUrl: $companyUrl
      preferredContent: $preferredContent
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;
